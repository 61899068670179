export const fetchLoadWorkOrders = loadModuleIds => {
  return $.ajax({
    url: '/admin/load_work_orders',
    data: {
      load_module_ids: loadModuleIds
    },
    dataType: 'json',
    contentType: 'application/json'
  });
};
